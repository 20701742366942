// @flow
import classNames from 'classnames';
import React from 'react';

import styles from './index.module.less';

type Props = {
  children: string,
  className?: string,
};

const SectionTitle = ({ children, className }: Props): React$Node => (
  <div className={classNames(className, styles.SectionTitle)}>{children}</div>
);

export default SectionTitle;
