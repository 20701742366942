// @flow
import './index.less';

import { Divider, Rate } from 'antd';
import React from 'react';

type Props = {
  rate: number,
  title: string,
  message: string,
  author: string,
};

const TestimonialCard = ({ rate, title, message, author }: Props): React$Node => (
  <div className="testimonial-card">
    <div className="rate">{rate.toFixed(1)}</div>
    <Divider className="divider" />
    <div className="title">{title}</div>
    <Rate disabled defaultValue={rate} />
    <div className="message">{message}</div>
    <div className="author">- {author}</div>
  </div>
);

export default TestimonialCard;
