// @flow
import './Testimonial.less';

import { Carousel } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import lodash from 'lodash';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';

import SectionName from '_components/SectionName';
import SectionTitle from '_components/SectionTitle';
import TestimonialCard from '_components/TestimonialCard';
import useBreakpoint from '_hooks/useBreakpoint';

import Section from '.';

const testimonials = [
  {
    title: 'Amazing!',
    message: 'A highly sophisticated app that includes timer, voice coach and even movement guides. Love it so much!',
    author: 'ebyyy',
  },
  {
    title: 'Awesome',
    message: 'The coach audio cues simply blow my mind. I’m sold.',
    author: 'tankermandi',
  },
  {
    title: 'Great app for beginners',
    message: 'I had such a great experience as a newbie to fitness. Definitely recommend!',
    author: 'jasmine',
  },
  {
    title: 'I was fat.',
    message: 'This app made me thin.',
    author: 'replaid',
  },
];

const TestimonialSection = (): React$Node => {
  const { t } = useTranslation();

  const isMobile = useBreakpoint({ max: 'md' });

  const testimonialElems = useMemo(
    () =>
      lodash.chunk(testimonials, isMobile ? 1 : 2).map((chunk, chunkIdx) => (
        <div key={`testimonial-chunk-${chunkIdx}`} className="testimonial-group">
          {chunk.map((testimonial, idx) => (
            <TestimonialCard
              key={`testimonial-${chunkIdx}-${idx}`}
              rate={5}
              title={testimonial.title}
              message={testimonial.message}
              author={testimonial.author}
            />
          ))}
        </div>
      )),
    [isMobile]
  );

  return (
    <Section triangles={['top-left']} triangleColor="#160f10" backgroundColor="#0f0809">
      <div className="testimonial-section-container">
        <div className="content-col">
          <SectionName>{t('Home_TestimonialSection_Name')}</SectionName>
          <SectionTitle>{t('Home_TestimonialSection_Title')}</SectionTitle>
          <Markdown>{t('Home_TestimonialSection_Description')}</Markdown>
        </div>
        <div className="testimonial-col">
          <Carousel>{testimonialElems}</Carousel>
        </div>
      </div>
    </Section>
  );
};

export default TestimonialSection;
