// @flow
import './Products.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';

import Button from '_components/Button';
import PricingCard from '_components/PricingCard';
import SectionName from '_components/SectionName';
import SectionTitle from '_components/SectionTitle';
import useBreakpoint from '_hooks/useBreakpoint';
import useProducts from '_hooks/useProducts';

import Section from '.';

const ProductsSection = (): React$Node => {
  const { t } = useTranslation();

  const isMobile = useBreakpoint({ max: 'md' });

  const { products } = useProducts();

  const productsElems = useMemo(() => {
    if (!products || products.length === 0) return null;

    return products[0].prices
      .filter((price) => price.active)
      .map((price, idx) => {
        let months = 1;
        switch (price.metadata.planType) {
          case 'annual':
            months = 12;
            break;

          case 'quarterly':
            months = 3;
            break;

          case 'monthly':
          default:
            months = 1;
            break;
        }

        const { savings } = price.metadata;

        return (
          <PricingCard
            type={isMobile ? 'simple' : 'default'}
            key={`pricing-card-${idx}`}
            name={t(price.metadata.name)}
            currency={price.currency.toUpperCase()}
            totalPrice={price.unitAmount / 100}
            freeTrialDays={price.recurring.trialPeriodDays}
            months={months}
            savings={savings && savings !== '0' ? savings : undefined}
            label={price.metadata.planType === 'annual' ? t('Common_BestValue') : undefined}
          />
        );
      });
  }, [t, products, isMobile]);

  return (
    <Section id="products" triangles={['top-right']} triangleColor="#0f0809" backgroundColor="#160f10">
      <div className="products-section-container">
        <div className="content">
          <SectionName>{t('Home_ProductsSection_Name')}</SectionName>
          <SectionTitle>{t('Home_ProductsSection_Title')}</SectionTitle>
          <Markdown>{t('Home_ProductsSection_Description')}</Markdown>
        </div>
        <div className="products">{productsElems}</div>
        <Button
          size="md"
          style={{ marginTop: isMobile ? '50px' : '43px', width: isMobile ? '100%' : 'auto' }}
          to="/checkout"
        >
          {t('Common_StartFreeTrial')}
        </Button>
      </div>
    </Section>
  );
};

export default ProductsSection;
