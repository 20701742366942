// @flow
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import Markdown from 'react-markdown';

import Button from '_components/Button';
import HeroSection from '_components/HeroSection';
import Layout from '_components/Layout';
import AppSection from '_components/Section/App';
import FeatureSection from '_components/Section/Feature';
import ProductsSection from '_components/Section/Products';
import TestimonialSection from '_components/Section/Testimonial';
import SectionName from '_components/SectionName';
import SectionTitle from '_components/SectionTitle';
import useAnalytics, { event } from '_hooks/useAnalytics';
import useBreakpoint from '_hooks/useBreakpoint';
import Feature1Image from '_images/feature-1.jpg';
import Feature2Image from '_images/feature-2.jpg';
import Feature3Image from '_images/feature-3.jpg';

const Home = (): React$Node => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const isMobile = useBreakpoint({ max: 'md' });

  useEffect(() => {
    track({
      group: event.group.Home,
      action: event.action.Viewed,
    });
  }, [track]);

  return (
    <Layout inverseFooter footerTrianglePosition="left">
      <HeroSection />
      <AppSection />
      <FeatureSection type="img-left" image={Feature1Image}>
        <SectionName>{t('Home_FeatureWorkouts_Name')}</SectionName>
        <SectionTitle>{t('Home_FeatureWorkouts_Title')}</SectionTitle>
        <Markdown className="description">{t('Home_FeatureWorkouts_Description')}</Markdown>
        <Button
          size="md"
          style={{ marginTop: isMobile ? '50px' : '43px', width: isMobile ? '100%' : 'auto' }}
          to="#products"
        >
          {t('Common_ViewSubscriptionOptions')}
        </Button>
      </FeatureSection>
      <FeatureSection type="img-right" image={Feature2Image}>
        <SectionName>{t('Home_FeatureCoaching_Name')}</SectionName>
        <SectionTitle>{t('Home_FeatureCoaching_Title')}</SectionTitle>
        <Markdown className="description">{t('Home_FeatureCoaching_Description')}</Markdown>
        <Button
          size="md"
          style={{ marginTop: isMobile ? '50px' : '43px', width: isMobile ? '100%' : 'auto' }}
          to="/checkout"
        >
          {t('Common_StartFreeTrial')}
        </Button>
      </FeatureSection>
      <FeatureSection type="img-left" image={Feature3Image}>
        <SectionName>{t('Home_FeatureTraining_Name')}</SectionName>
        <SectionTitle>{t('Home_FeatureTraining_Title')}</SectionTitle>
        <Markdown>{t('Home_FeatureTraining_Description')}</Markdown>
        <Button
          size="md"
          style={{ marginTop: isMobile ? '50px' : '43px', width: isMobile ? '100%' : 'auto' }}
          to="/checkout"
        >
          {t('Common_StartTrial')}
        </Button>
      </FeatureSection>
      <TestimonialSection />
      <ProductsSection />
    </Layout>
  );
};

export default Home;
