// @flow
import './index.less';

import classNames from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';

type Props = {
  children?: React$Node,
  className?: string,
  style?: Object,
  to?: string,
  type?: 'default' | 'menu-btn' | 'menu-bordered-btn',
  size?: 'md' | 'lg',
  disabled?: boolean,
  onClick?: () => void | Promise<any>,
};

const Button = ({
  children,
  className,
  style,
  to,
  type = 'default',
  size = 'lg',
  disabled = false,
  onClick,
}: Props): React$Node => {
  const classes = useMemo(
    () =>
      classNames(
        'btn',
        {
          'menu-btn': type === 'menu-btn',
          'menu-bordered-btn': type === 'menu-bordered-btn',
          md: type === 'default' && size === 'md',
          lg: type === 'default' && size === 'lg',
          disabled,
        },
        !to && className
      ),
    [className, to, size, type, disabled]
  );

  const linkClasses = useMemo(
    () =>
      classNames(
        'btn-link',
        {
          'menu-btn': type === 'menu-btn',
          'menu-bordered-btn': type === 'menu-bordered-btn',
        },
        to && className
      ),
    [className, to, type]
  );

  if (to !== undefined) {
    return (
      <Link to={to} className={linkClasses} style={style}>
        <button type="button" className={classes} style={{ width: '100%' }}>
          <span>{children}</span>
        </button>
      </Link>
    );
  }

  return (
    <button type="button" className={classes} style={style} onClick={onClick}>
      <span>{children}</span>
    </button>
  );
};

export default Button;
