// @flow
import './index.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo, useState } from 'react';
import ModalVideo from 'react-modal-video';

import AppStoreBadges from '_components/AppStoreBadges';
import Button from '_components/Button';
import useBreakpoint from '_hooks/useBreakpoint';
import useProducts from '_hooks/useProducts';
import BgPosterImage from '_images/bg-hero.jpg';
import { round } from '_utils/math';

const HeroSection = (): React$Node => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint({ max: 'md' });

  const [videoVisible, setVideoVisible] = useState(false);
  const { products } = useProducts();

  const cheapestMonthlyPrice = useMemo(() => {
    if (!products || products.length === 0) return null;

    const cheapestMonthlyAmount = products[0].prices
      .filter((price) => price.active)
      .map((price) => {
        let months = 1;
        switch (price.metadata.planType) {
          case 'annual':
            months = 12;
            break;

          case 'quarterly':
            months = 3;
            break;

          case 'monthly':
          default:
            months = 1;
            break;
        }

        return price.unitAmount / 100 / months;
      })
      .reduce((a, b) => {
        if (a === null) return b;
        return a > b ? b : a;
      }, null);

    return `$${round(cheapestMonthlyAmount, 2)}`;
  }, [products]);

  return (
    <section className="hero">
      <video className="bg-video" playsInline autoPlay muted loop poster={BgPosterImage}>
        <source src={isMobile ? '/portrait.mp4' : '/landscape.mp4'} type="video/mp4" />
      </video>
      <div className="container">
        <div className="title" dangerouslySetInnerHTML={{ __html: t('Home_HeroSection_Title') }} />
        <div className="description">{t('Home_HeroSection_Description', { price: cheapestMonthlyPrice })}</div>
        <Button className="watch-btn" onClick={() => setVideoVisible(true)}>
          {t('Common_WatchVideo')}
        </Button>
      </div>
      <AppStoreBadges className="badges" vertical />
      <ModalVideo channel="custom" url="/intro.mp4" isOpen={videoVisible} onClose={() => setVideoVisible(false)} />
    </section>
  );
};

export default HeroSection;
