// @flow
import './index.less';

import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';

import { round } from '_utils/math';

type Props = {
  type?: 'default' | 'simple',
  name: string,
  currency: string,
  totalPrice: number,
  freeTrialDays: number,
  savings?: string,
  months: number,
  label?: string,
};

const PricingCard = ({
  type = 'default',
  name,
  currency,
  totalPrice,
  freeTrialDays,
  savings,
  months,
  label,
}: Props): React$Node => {
  const { t } = useTranslation();

  const price = useMemo(() => round(totalPrice / months, 2), [totalPrice, months]);
  const description = useMemo(() => {
    const params = { currency, totalPrice, freeTrialDays };

    switch (months) {
      case 12:
        return t(
          freeTrialDays === 0 ? 'PricingCard_Annually_Description_NoFreeTrial' : 'PricingCard_Annually_Description',
          params
        );

      case 3:
        return t(
          freeTrialDays === 0 ? 'PricingCard_Quarterly_Description_NoFreeTrial' : 'PricingCard_Quarterly_Description',
          params
        );

      default:
        return t(
          freeTrialDays === 0 ? 'PricingCard_Monthly_Description_NoFreeTrial' : 'PricingCard_Monthly_Description',
          params
        );
    }
  }, [t, currency, months, totalPrice, freeTrialDays]);

  if (type === 'simple') {
    return (
      <div
        className={classNames('pricing-card', 'pricing-card-simple', {
          'pricing-labeled-card': !!label,
        })}
      >
        {label && <div className="label">{label}</div>}
        <div className="content-container">
          <div className="name">{name}</div>
          <Markdown className="description">{description}</Markdown>
        </div>
        <div className="price-container">
          <div className="price">
            <span className="currency">$</span>
            <span className="text">{price}</span>
          </div>
          <div className="unit">{t('PricingCard_PerMonth')}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('pricing-card', 'pricing-card-default', { 'pricing-labeled-card': !!label })}>
      {label && <div className="label">{label}</div>}
      <div className="name">{name}</div>
      <div className="price-container">
        <div className="price">
          <span className="currency">$</span>
          <span className="text">{price}</span>
          <span className="unit">{t('PricingCard_PerMonth_ShortForm')}</span>
        </div>
        {savings && <div className="discount">{`(${t('Common_Saving', { savings })})`}</div>}
      </div>
      <Markdown className="description">{description}</Markdown>
    </div>
  );
};

export default PricingCard;
