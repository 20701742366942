// @flow
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

// Breakpoints follow antd's default breakpoints, so that it matches up when using antd Row/Col components
const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 480,
};

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

type Options = {
  min?: Size,
  max?: Size,
};

const useBreakpoint = ({ min, max }: Options): boolean => {
  const query = useMemo(() => {
    const queries = [];
    if (min) {
      queries.push(`(min-width: ${breakpoints[min]}px)`);
    }
    if (max) {
      queries.push(`(max-width: ${breakpoints[max] - 1}px)`);
    }

    return queries.join(' and ');
  }, [min, max]);

  return useMediaQuery({ query });
};

export default useBreakpoint;
