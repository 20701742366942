// @flow
import './App.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Markdown from 'react-markdown';

import Button from '_components/Button';
import SectionName from '_components/SectionName';
import SectionTitle from '_components/SectionTitle';
import AwardAppStoreImage from '_images/awards/award-appstore.svg';
import AwardPlayStoreImage from '_images/awards/award-playstore.svg';
import LogoImage from '_images/logo.png';

import Section from '.';

const AppSection = (): React$Node => {
  const { t } = useTranslation();

  return (
    <Section backgroundColor="#0f0809">
      <div className="app-section-container">
        <div className="content-container">
          <img className="logo" src={LogoImage} alt="Ritual FIT logo" />
          <div className="content">
            <SectionName>{t('Home_AppSection_Name')}</SectionName>
            <SectionTitle>{t('Home_AppSection_Title')}</SectionTitle>
            <Markdown className="features">{t('Home_AppSection_Features')}</Markdown>
            <Button className="start-trial-btn" size="md" to="/checkout">
              {t('Common_StartFreeTrial')}
            </Button>
          </div>
          <div className="awards">
            <img src={AwardAppStoreImage} alt="Award App Store" />
            <img src={AwardPlayStoreImage} alt="Award App Store" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AppSection;
