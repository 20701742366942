// @flow
import './Feature.less';

import classNames from 'classnames';
import React, { useMemo } from 'react';

import Section from '.';

type Props = {
  type?: 'img-left' | 'img-right',
  image: number | string,
  children: React$Node,
};

const FeatureSection = ({ type = 'img-left', image, children }: Props): React$Node => {
  const isImageLeft = useMemo(() => type === 'img-left', [type]);

  return (
    <Section
      triangles={[isImageLeft ? 'top-right' : 'top-left']}
      triangleColor={isImageLeft ? '#0f0809' : '#160f10'}
      backgroundColor={isImageLeft ? '#160f10' : '#0f0809'}
    >
      <div className={classNames('feature-section-container', `feature-section-container-${type}`)}>
        <div className="image-col">
          <img src={image} alt="feature" />
        </div>
        <div className="content-col">{children}</div>
      </div>
    </Section>
  );
};

export default FeatureSection;
