// @flow
import './index.less';

import React, { useMemo } from 'react';

type Props = {
  children?: React$Node,
  triangles?: $ReadOnlyArray<'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'>,
  triangleColor?: string,
  backgroundColor?: string,
  id?: string,
};

const Section = ({
  children,
  triangles,
  triangleColor = '#0f0809',
  backgroundColor = '#160f10',
  id,
}: Props): React$Node => {
  const triangleElems = useMemo(() => {
    if (!triangles) return;

    return triangles.map((pos, idx) => {
      const linearGradientPosition = {
        'top-left': 'left top',
        'top-right': 'right top',
        'bottom-left': 'left bottom',
        'bottom-right': 'right bottom',
      }[pos];

      return (
        <div
          key={`triangle-${idx}`}
          className={`triangle triangle-${pos}`}
          style={{
            background: `linear-gradient(to ${linearGradientPosition}, transparent 0%, transparent calc(50% - 1px), ${triangleColor} 50%)`,
          }}
        />
      );
    });
  }, [triangles, triangleColor]);

  return (
    <section
      id={id}
      className="section"
      style={{ backgroundColor: triangles && triangles.length > 0 ? backgroundColor : triangleColor }}
    >
      {triangleElems}
      {children}
    </section>
  );
};

export default Section;
